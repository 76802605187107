.Contact_body{
  background-color: #262626;;
  padding-top:100px;
  padding-bottom:100px;
}
.Contact_container{
 
  display:flex;
  flex-direction: row;
	flex-wrap: wrap;
  align-items: center;
	width:60%;
	background-color:rgb(209, 200, 200);
  margin-left:auto;
  margin-right:auto;
  padding:100px;
    
}


.Contact_pics, .About_pics{
  width: 45%;
	margin: auto;
  display:block;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}
.row {
    display: flex;
    clear: both;
}


.column {
    width: 50%;
    margin-top: 6px;
    padding: 20px;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }

  .row_mb{
	margin-bottom:50px;
	background-color:#a29db4;
	
}
.row_mb hr{
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 4px;
      
  }
.Message_box{
	width:50%;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:50px;
	margin-top:50px;
	
}

form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom:50px;
}

form .textbox_left_upper {
    width: 46%;
}
form .textbox_left_lower{
    width: 100%;
}



.textbox_button{
    width:30%;
    
}


form input[type=submit] {
    width: 100%;
}	
input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input[type=submit] {
    background-color: rgb(167, 11, 11);;
    color: white;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #d6adaf;
  }

  .Contact_pic, .About_pics{
	
	width: 100%;
	margin: auto;
	margin-bottom:50px;

	
}
.Contact_pics img, .Contact_pic_mobile img{
  
 
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 90%;
  /* border-style: solid; */
  border-top-left-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
}

.About_pics img, .About_pics_mobile img{
 
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 100%;
  display: block;
}
.message_title{
	background-color:inherit;
	margin-left:auto;
	margin-right:auto;
	padding-top:1px;
	background-color:#993643;
	
	
}

.message_2U{
  font-family: 'EB Garamond', serif;;
  width: 60%;
	font-size: 22px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-bottom:60px;	
}
.MobileCongtact_container{
    display:bl;
    Height:100px;
    background-color: black;
    color:white;
  
  }
  
  
  @media screen and (max-width: 1200px) {
  
    .MobileCongtact_container{
        display: block;
    }
    .Contact_container{
      padding:20px;
    }

    .Contact_pics{
      width:50%;
    }

    .Contact_pic_mobile{
       display:block;
    }

    .column {
      width: auto;      
    }
  }

  
  @media screen and (max-width: 800px) {
    .Contact_container{
      width:95%;
    }
  }