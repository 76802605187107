
.intro{
	display:flex;
	width:70%;
    background: inherit;
	height:500px;
	border-radius: 10px;
	margin-bottom:85px;
	margin-left:auto;
	margin-right:auto;
	padding-left: 10px;
	padding-right: 50px;
	justify-content:space-around;
	background:   url(SiljaCozy.jpg);
	background-position: center right;
	background-size: cover; 
	background-color:#a6a6c2;
	background-blend-mode: multiply;
	font-family:'Cinzel', serif;
	
}

 .Container_Writing_list{
	display:flex;
	padding:20px;
	margin-bottom: 60px;
	padding-right: 20px;
	padding-left: 60px;
  
   }

.Writing_list
 {
	width: 85%;
	height: auto;
	position: relative;
	z-index: 1;
	background-color: white;
	overflow:auto;
	padding-top:0px;
	margin-left: auto;
	margin-right:auto;
	border-style: solid;
  }

.R_work h2{
	color:rgb(43, 39, 39); 
	font-weight: 700;
	font-family: 'EB Garamond', serif;
	font-size: 22px;
	margin-bottom: 1px;
	margin-top: 1px;
	
	}
	
.R_work h2:hover{
	color:rgb(187, 42, 42); 
	text-decoration: underline;
	margin-bottom: 1px;
	margin-top: 1px;
	
	}
.R_work h3{
	color:rgb(156, 23, 23); 
	font-weight: 700;
	font-family: 'EB Garamond', serif;
	font-size: 16px;
	margin-bottom: 4px;
	
	}
	
.writing_sample{
	padding-left: 20px;
	
	
}
.works_pic{
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	margin-left:auto; 
	margin-right:auto;
	background-color: rgb(255, 255, 255);
	background-image: url(bird_in_tree.jpg);
	background-repeat:no-repeat;
	background-position: center;
	background-size: cover;
	border: #262626;
	border-style: solid;
	border-width: 4px;
	margin-top: 20px;
	margin-bottom: 20px;
	width:30%;
	}

.works_pic img{
	margin-left:auto;
	margin-right:auto; 
	width:400px; 
	height:550px;

}
.works_list{
	flex-grow: 1.5;
	flex-shrink: 1;
	flex-basis: 0;
	margin-left:auto; 
	margin-right:auto;
	background-color: rgb(255, 255, 255);
	padding:20px;

 }

 .Landing_page{
	width:85%;
	background: url(rect4.png) no-repeat;
    background-size: cover;
	background-position:center;
	height:350px;
	text-align: center;
	color: thistle;
	padding-top: 80px;
	margin-left:auto;
	margin-right:auto;

		
}

.Landing_page h3{
 float:right;
 padding-right: 60px;
 color:#bbadb0;


} 

.Recent_blogs{
	display:flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	background:inherit;
	margin-bottom: 20px;
	padding-left: 30px;
	padding-right:30px;
	}
	.Recent_poems .blog_pic{
		width:30%;
	}
	.Recent_poems .blog_pic img{
		width:300px;
	}
	.Recent_blogs h3{
		color: black
	}
	.Recent_blogs h3:hover{
		color: #7d0d27;
		text-decoration: underline;

	}
	.R_blog{
		padding:20px;
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		color:rgb(58, 55, 55);
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 0;
		box-shadow: 0 0 1rem 0 rgba(65, 44, 44, 0.322); 
		text-align: left;
		background-color:rgba(255, 255, 255, 0.815) ;
		font-weight: lighter;
		font-size: 16px;
		width:45%;
	
	}

	.Blog_News_Button{

	display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
	
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	width: 100%;
	height: auto;
	background-color:inherit;
	
	}
	
	.latest_writing{
	
		width:90%;
		margin-left: auto;
		margin-right: auto;
		height:auto;
		background-color: rgb(214, 214, 214);
		margin-top:0px;
		padding-top: 40px;
		padding-bottom: 20px;
		
	}
	.tab_container{
	 margin-left: 60px;
	 padding-top: 40px;
	 padding-bottom: 40px;
	 width:90%;
	
	}
	.tab_container h1{
	font-size: 25px;    
	display:inline;
	font-family: 'Cinzel', serif;
	color:rgb(36, 36, 36);
	
	}
	
	.blog_news
	{
		background-color:white;
		width: 85%;
		margin-left: auto;
		margin-right: auto;
		height: auto;
		padding-bottom: 80px;

	}

	.sk_footer{
		padding: 10px 10px;
		background:#262626;
		width:100%;
		height:100px;
		box-sizing:border-box;
	
		
	}
	
	.footer hr{
	  display: block;
	  margin-left: auto;
	  margin-right: auto;
	  border-style: inset;
	  margin-top: 20px;
	  border-width: 2px;
		
	}
	
	.sk_icons h3{
		color:rgb(255, 255, 255);
		margin-left: auto;
		margin-right: auto;
		font-size: 1.5em;
	   }
	   
	
	.sk_footer h3{
	 color:white;
	 margin-left: auto;
	 margin-right: auto;
	text-align: center;
	}

	.sk_links{
		padding: 10px 10px;
		background:#7d0d27;
		width:100%;
		height: 200px;
		box-sizing:border-box;
		display:flex;
		justify-content: space-evenly;
		text-align: center;
	
		
	}
	
	
	.sk_links form{
		display:inline;
		
		
	}
	.icons{
		display:inline;
		margin-right:12px;
		margin-left:12px;
		float:right;
		color:rgb(255, 255, 255);
   }
   
   .icons a{
	   
		color:rgb(255, 255, 255);
		text-decoration: none;
   }
   .fa-2x {
    font-size: 2.5em;
    }

   .MobileHome_container{
	display:none;
	Height:100px;
	background-color: black;
	color:white;
  
  }

  .Home_divider{
	  background-color:#262626;
	  height: 100px;
  }
  .welcome_text h1{
	color:white;
	font-size: 4vw;

  }
  .welcome_text h2{
	color:white;
	font-size: 2vw;

  }


  .welcome_text{
 
	width: 50%;
	padding-top:40px;
	padding-bottom:40px;
	padding-right:400px;
    height: 90vh;
    color:white;
	

	
}

.footer_hr{
	padding: 10px 10px;
	background:#f7f6f6;
	height: 2px;
}
.piece_desc{
	width:80%;
}

.R_date{
	margin-bottom: 20px;
}
.button{
	color: rgb(156, 23, 23);
	text-decoration: none;
}
.button:hover{
	color:blue;
}  

.write-links{
	text-decoration: none;

}
  

  @media screen and (max-width: 960px) {

	.sk_links{
		height:400px;
		flex-direction: column 
	}
    .sk_icons{	
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
	.MobileHome_container{
		display: block;
	}

	.Landing_page h3{
		font-size: 14px;
	}


	.Landing_page h2{
		font-size: 16px;
	}

	.second{

		display:none;
	
	}

	.works_pic{
		display: none;
	}

	.welcome_text h1{
		color:white;
		font-size:35px;
	
	  }
	  .welcome_text h2{
		color:white;
		font-size:15px;
	
	  }
	
	
	  .welcome_text{
	 
		width: 50%;
		padding-top:40px;
		padding-bottom:40px;
		padding-right:100px;
		height: auto;
		color:white;
		
	
		
	}
	.tab_container{
		margin-left: 20px;
	}

	.Container_Writing_list{
		padding:0px;
		margin-bottom: 60px;
		padding-left: 0px;
	  
	   }
  }

  