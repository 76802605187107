.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.home_container{
  width: 100%;
  height: 100%;
  margin:0;
  padding:0;
  font-family: 'EB Garamond', serif;
  background-color:#262626;
  
}

.MobileTitle_container{
  display:none;
  Height:100px;
  background-color: black;
  color:#7d0d27;
  height: 120px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  font-family: 'Cinzel', serif;
  padding-top: 80px;
}

.MobileTitle_subtext{
  color: white;
  font-size: 16px;
  margin-top: 20px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@media screen and (max-width: 960px) {
  
	.MobileTitle_container{
		display: block;
	}
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
