.navbar
{
 

    border:1px solid #ccc;
    border-width:1px 0;
    list-style:none;
    margin:0;
   	padding:15px;
  	background-color: rgb(36, 32, 32);
    text-align:center;
	
}

.navbar-logo
{
	color:#DC143C;
	height: 220px;
	font-size:50px;
  text-align: center;
	font-family: 'Cinzel', serif;
	font-weight: bold;
	margin-bottom:0px;
	background-position: center right;
	background-size: cover; 
  background: url(branches.jpg);
	background-color:#4e4e7a;
	background-blend-mode: multiply;
  text-decoration: none;
  cursor:default;
 
	
}

.logo h1{
  margin-top: 0px;	
  margin-bottom: 10px;
  padding:0px;
  text-decoration: none;
  }
  
.logo h1{
    padding-top: 20px;	
 }

.navbar ul
{
 margin: 0;
 padding:0;
}

.navbar ul li{
	 display:inline;
}




.navbar ul li {
	display:inline-block;
	font-size:24px;
  padding:10px;
	margin-left: 50px;
	color:white;
	font-family: 'Cinzel', serif;
	text-decoration: none;
}
header nav ul li a:hover ,header nav ul li a:active{
	
	color: #DC143C;
	

}
.subtext{
  color:rgb(143, 78, 78);
  height: 50px;
  font-size:24px;
  text-align: center;
  font-family: 'Cinzel', serif;
  margin-bottom:20px;
  margin-top:0px;
  text-decoration: none;

}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}


.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;

}
@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .navbar
  {
    border: none;
    padding: 0px;
  }
  .navbar ul li {
    display:inline-block;
    font-size:24px;
    padding-top:10px;
    padding-bottom:10px;
    margin-left: 0px;
    color:white;
    font-family: 'Cinzel', serif;
    text-decoration: none;
  }
  .MobileTitle_subtext{
    display:none;
    color: black;
    visibility: hidden;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -200%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    display:none;
    cursor:default;
  
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
