.btn-group {
    -webkit-transition: all 1.5s ease-in-out;
    transition: all 1.5s ease-in-out;
    top: 20px;
    position: sticky;
  }
  
  
  
  .btn-group .button {
    background-color: inherit; /* Green */
    border: none;
    color: #5F9EA0;
    text-align: left;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: block;
    margin :10px;
  }
  
  .btn-group .button:not(:last-child) {
    border-bottom: none; /* Prevent double borders */
  }
  
  .btn-group .button:hover {
    color: black;
  }
  
  .menu{
      padding:10px 0px 0px 0px;
      font-family: 'Nanum Myeongjo', serif;
      font-weight:300;
      font-size: 14px;
      background-color: #a32136;
           background: 
      linear-gradient(
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.2)
      ),
      url(long_branch2.jpg);
      background-repeat: no-repeat;
      background-attachment: fixed;
      border-style: solid;
      border-color:#262626;
      border-width: 0px 10px 10px;
      padding-bottom: 100px;
      color:white;
      width:80%;
      margin-right: 40px;
      flex:1 1 auto;
      
      
  }
  
  .menu h2{
      
      font-family: 'Nanum Myeongjo', serif;
      text-align:justify ;
      font-weight:bold;
      font-size: 18px;
      color:white;
      margin-bottom: 40px;
      margin-top: 20px;
      margin-left: 20px;
      color:white;
      text-decoration: none;
      
  }
  .menu a{
      
      color:black;
      text-transform: uppercase;
      color:white;
      text-decoration: none;
      
      
  }
  .menu h3{
      margin-bottom: 20px;
      color:white;
      text-decoration: none;
      
  }
  .menu h3:hover{
       text-decoration: underline;
      
  }

  
  .reading_container {
    display: flex; /* or inline-flex */
    flex-direction: row;
    margin:0px;
    padding-top: 100px;
    padding-bottom: 50px;
    padding-right: 80px;
    padding-left: 80px;
    background-color:#262626;
  
  }
  @media screen and (max-width: 960px) {
    
  .menu{
   display:none;
  }
  .reading_container {
    padding-left: 20px;
    padding-right: 20px;
  }
}