.poemlist{

	color: #5F9EA0;
	font-style: italic;
	align-self: center;
    text-align: left;
    display:flex;
	flex-wrap: wrap;
	justify-content:space-evenly;
	padding-left: 50px;
	padding-bottom:100px;
	border-width: 0px 0px 10px;
	border-style: solid;
	border-color:rgb(36, 32, 32);
	color: black;
	background-color: #fff;
}
.container{
	background-color:white;
	margin:0px;
	flex: 2 1 auto;

}
.container hr{
	width:98%;

}
.container h1{
	width:100%;
	margin-left:20px;

}
.container .poemItem h1{
	width:100%;
	margin-left:0px;

}
.menu h2{
	
	font-family: 'Nanum Myeongjo', serif;
	text-align:justify ;
    font-weight:bold;
    font-size: 26px;
	color:white;
	margin-bottom: 40px;
	margin-top: 40px;
	
	color:white;
	
}
.menu a{
	
	color:black;
	text-transform: uppercase;
	color:white;
	
	
}
.menu h3{
	margin-bottom: 20px;
	color:white;
	text-align: left;
	font-size: 16px;
	margin-left: 40px;
	
}
.menu h3:hover{
	 text-decoration: underline;
	
}


.MobileReading_container{
	display:none;
	Height:100px;
	background-color: black;
	color:white;
  
  }
  
  
  @media screen and (max-width: 960px) {
  
	.MobileReading_container{
		display: block;
	}
  }