.project_container{
    display:flex;
    flex-direction: row;
	flex-wrap: wrap;
    align-items: center;
	width:100%;
	justify-content: center;
	background-color:rgb(209, 200, 200);
    
}


.project_container:first-child{
    height:100px;
    
}
.title_box{
	font-family: 'Cinzel', serif;
	height:auto;
	text-align: left;
	vertical-align: middle;
	padding-top:20px;
}
.title_box h1{
	
	margin:0px;
	color:#a7a1a2;
	margin-left:20px;
	font-size: 2em;
	vertical-align: middle;
}
.centerIt{
	margin-left:auto;
	margin-right:auto;
	width:auto;

}

.credit{
	margin-bottom: 20px;
	margin-right: 20px;
}
.credits_list{
	display:flex;
	justify-content: space-around;
	flex-wrap: wrap;

}
.project_details{
	margin-left:auto;
	margin-right:auto;
	width:45%;
	font-size: 20px;
	font-family: 'EB Garamond', serif;
	font-weight: 600;
}

.project_pics{
	width:35%;
	
	background-color:#262626;
	
}
.project_pics figcaption{
	font-size:18px;
	font-family: 'EB Garamond', serif;
	color: #fcfcfc;
	text-align: center;

	
	
}
.project_pics img{
	display: block;
	width:65%;
	margin-left:auto;
	margin-right:auto;
	
}

.project_divide{
	width:100%;
	height: 45px;
	background-color: #262626;
}
.project_divide{
	width:100%;
	height: 100px;
	background-color: #262626;
}
.MobileProjects_container{
	display:none;
    Height:100px;
    background-color: black;
    color: #DC143C;
    height: 120px;
    font-size: 28px;
    text-align: center;
    font-family: 'Cinzel', serif;
  
  }
  
  .MobileProjects_Img{
	  display:none;
  }
  @media screen and (max-width: 960px) {
  
	.MobileProjects_container{
		display: block;
	}
  
	.MobileProjects_Img {
		display:block;
		width:50%;
	}
	.MobileProjects_Img img {
		display:block;
		width: 70%;
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom:20px;
	}
	.project_details{
		margin-left:auto;
		margin-right:auto;
		width:70%;
		font-size: 16px;
		margin-bottom: 60px;
	}

	.project_pics{

	display:none;
	}

	.title_box h1{

		font-size: 20px;
	}


}
