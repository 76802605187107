.blur {
    position: relative;
    margin-top:20px;
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 28px;
    padding:50px 20px;
    background: rgba( 255, 255, 255, 0.7 );
    border: solid 1px rgba(255,255,255,0.3);
    backdrop-filter: blur(5px );
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5), -1px -1px 2px #aaa,
    1px 1px 2px #555;
  }

.blog_container{
	width:85%;
  	background: url(bird_in_tree.jpg);
	background-color:#58585e;
	background-blend-mode: multiply;
	background-position: center;
	background-size: cover;
	margin-left: auto;
  	margin-right: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-bottom: 20px;
	display:flex;
	flex-direction:column;
}

.blog_body{
	background-color: #262626;
	padding-top: 100px;
	padding-bottom: 100px;
}

.MobileBlog_container{
	display:none;
    Height:100px;
    background-color: black;
    color: #DC143C;
    height: 120px;
    font-size: 28px;
    text-align: center;
    font-family: 'Cinzel', serif;

}

.Container_Blog_list{
	display:flex;
	padding:20px;
	margin-bottom: 60px;
	padding-right: 20px;
	padding-left: 60px;
  
   }

.Writing_list
 {
	width: 85%;
	height: auto;
	position: relative;
	z-index: 1;
	background-color: white;
	overflow:auto;
	padding-top:0px;
	margin-left: auto;
	margin-right:auto;
	border-style: solid;
  }

.R_work h2{
	color:rgb(43, 39, 39); 
	font-weight: 700;
	font-family: 'EB Garamond', serif;
	font-size: 22px;
	margin-bottom: 1px;
	margin-top: 1px;
	
	}
	
.R_work h2:hover{
	color:rgb(187, 42, 42); 
	text-decoration: underline;
	margin-bottom: 1px;
	margin-top: 1px;
	
	}
.R_work h3{
	color:rgb(156, 23, 23); 
	font-weight: 700;
	font-family: 'EB Garamond', serif;
	font-size: 16px;
	margin-bottom: 4px;
	
	}
	
.writing_sample{
	padding-left: 20px;
	
	
}
.works_pic{
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	margin-left:auto; 
	margin-right:auto;
	background-color: rgb(255, 255, 255);
	background-image: url(bird_in_tree.jpg);
	background-repeat:no-repeat;
	background-position: center;
	background-size: cover;
	border: #262626;
	border-style: solid;
	border-width: 4px;
	margin-top: 50px;
	margin-bottom: 20px;
	width:30%;
	}

.works_pic img{
	margin-left:auto;
	margin-right:auto; 
	width:400px; 
	height:550px;

}
.works_list{
	flex-grow: 1.5;
	flex-shrink: 1;
	flex-basis: 0;
	margin-left:auto; 
	margin-right:auto;
	background-color: rgb(255, 255, 255);
	padding:20px;

 }

 .blog_text{
	width:85%;
	padding-top: 60px;
	border-style: solid;
	border-width: 4px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
	background-color: white;

 }
 .blog_text h2{
	margin-bottom: 50px;
 }

 .back_blog{
	margin-left: 100px;
	font-size: 16px;
	font-weight: bold;
 }

 @media screen and (max-width: 960px) {
  
	.MobileBlog_container{
		display: block;
	}
	.message_2U{
		font-size: 18px;
		width: 80%;
	}
	.back_blog {
		margin-left: 20px;
	}
}

@media screen and (max-width: 600px){

	.message_2U{
		font-size: 14px;

	}
	
}
