
.About_container{
 
    display:flex;
  flex-direction: row;
	flex-wrap: wrap;
  align-items: center;
	width:75%;
	background-color:rgb(209, 200, 200);
  margin-left:auto;
  margin-right:auto;
    
  }

.profile_pic img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    /* border-style: solid; */
     border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
      
  }

  .Bio{

	width:80%;
	background: white;
	margin-left: auto;
    margin-right: auto;
	margin-top: 60px;
	border-style: solid;
	border-width: 5px;
	overflow-y: auto;
	padding-bottom: 40px;
	
}

.profile_pic{

    margin-left: auto;
    margin-right: auto;
    padding-top: 60px ;
       
   }

   
.Bio{

	width:80%;
	background: white;
	margin-left: auto;
    margin-right: auto;
	margin-top: 60px;
	border-style: solid;
	border-width: 5px;
	overflow-y: auto;
	padding-bottom: 40px;
	
	
}

.about_me{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  text-align:justify ;
  font-family: 'EB Garamond', serif;
  font-weight:500;
  font-size: 20px;
	
	
}

.about_me h2{

  text-align: center;
  font-family: 'EB Garamond', serif;
  font-weight:500;
  font-size: 34px;	
}

.PPS{
	font-family: 'EB Garamond', serif;
	font-size: 18px;
}

.MobileAbout_container{
    display:none;
    Height:100px;
    background-color: black;
    color: #DC143C;
    height: 120px;
    font-size: 28px;
    text-align: center;
    font-family: 'Cinzel', serif;

}


@media screen and (max-width: 960px) {

    .MobileAbout_container{
        display: block;
    }
}

@media screen and (max-width: 800px) {
    .Contact_container{
      width:95%;
    }
  }