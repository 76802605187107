#mailchimp {
	background:inherit;
}
	
#mailchimp h3{
  color:rgb(255, 255, 255);
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5em;
   }
   

#mailchimp input{
    color: rgb(65, 62, 62);
    font-family: 'EB Garamond', serif;
    font-size: 16px;
    font-style: italic;
    margin-bottom: 10px;
    padding: 8px 10px;
}
#mailchimp button[type="submit"] {

  color: rgb(255, 254, 254);
  cursor: pointer;
  font-size: 15px;
  width: 100px;
  padding: 8px 0;
  margin-left:20px;
  background-color:rgb(59, 53, 53);
  font-family: 'EB Garamond', serif;
  font-size: 18px;

}


@media screen and (max-width: 960px) {
    #mailchimp  {

         margin-left:auto;
         margin-right:auto;
         margin-top:60px;
      
      }
}