.poemItem{
	margin-left:15px;
	font-family: 'Nanum Myeongjo', serif;
    font-weight:300;
    font-size: 16px;
	font-style:normal;
	color: black;
	position: relative;
	overflow: hidden;
	width:70%;
	margin-bottom: 50px;
	
	
}

.SSItem{
	margin-left:auto;
	margin-right: auto;
	font-family: 'Nanum Myeongjo', serif;
    font-weight:300;
    font-size: 16px;
	font-style:normal;
	color: black;
	position: relative;
	overflow: hidden;
	width:70%;
	text-align: center;
	
	
}
.storylist .button{
	margin-left: 15%;

}
#FOBTH{
	text-align: center;

}

.writeItem {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 46%;
	margin-bottom: 40px;

}
.writeItem a{
 	margin-left:5px;
	margin-top:25px;
}

.SSItem .fade_out,
.poemItem .fade_out { 
  position: absolute; 
  bottom: 0; 
  width: 90%; 
  text-align: right; 
  margin-right: 0; 
  padding: 80px 0; 
  
	
  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, #fff);
}
@media screen and (max-width: 960px) {
	.poemItem{
	width: 90%;
	font-size: 2vw;
	}
	.writeItem {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;
	
	}

	#FOBTH{
		text-align:inherit;
	
	}

	.SSItem{
		width: 90%;
		font-size: 2vw;

	}
}
	@media screen and (max-width: 600px){

		.Hezz_vid{
			display: none;
		}
	}